import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Notification from 'components/Notification';
import { EXCHANGE } from 'constants/app';
import { BetSides } from 'constants/myBets';
import ProgressBar from 'pages/mobile/MobileBetListPage/components/OpenBet/components/ProgressBar/ProgressBar';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { setCancelledBet } from 'redux/modules/betList';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import {
  getIsMarketSuspended,
  getMarketPricesIsSelectionNonRunner,
  getMarketPricesRunnerLockedBySelectionId
} from 'redux/modules/marketsPrices/selectors';
import { setMobileEditOfferId } from 'redux/modules/myBets';
import { cancelBets } from 'redux/modules/placement';

import styles from './UnmatchedActions.module.scss';

interface UnmatchedActionsProps {
  bet: TCurrentBet;
  isCancelled: boolean;
}

const UnmatchedActions = ({ bet, isCancelled }: UnmatchedActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSuspended = useSelector(getIsMarketSuspended(bet.marketId));
  const isSelectionLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId ?? 0, Number(bet.handicap ?? 0))
  );
  const isNonRunner = useSelector(
    getMarketPricesIsSelectionNonRunner(bet.marketId, bet.selectionId ?? 0, Number(bet.handicap ?? 0))
  );

  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isSuccessCancelled, setIsSuccessCancelled] = useState<boolean>(false);
  const [cancellingError, setCancellingError] = useState<string | null>(null);

  const { marketLink } = useOpenBetData(bet);
  const isLocked =
    isSuspended || isSelectionLocked || (bet.side === BetSides.Lay && bet.disabledLayOdds) || isNonRunner;

  const navigateToMarket = () => {
    dispatch(setMobileEditOfferId(bet.offerId));
    navigate(marketLink);
  };

  const cancelBet = () => {
    setCancellingError(null);
    setIsCancelling(true);

    dispatch(
      cancelBets({
        data: {
          [bet.marketId]: [
            {
              betType: bet.betType || EXCHANGE,
              handicap: bet.handicap,
              offerId: bet.offerId,
              price: bet.price,
              selectionId: bet.selectionId,
              size: bet.size
            }
          ]
        },
        successCallback: () => {
          setIsSuccessCancelled(true);
          dispatch(setCancelledBet(bet.offerId));
        },
        errorCallback: error => {
          const errorMessage = typeof error === 'string' ? error : error?.data?.message || error.toString();
          setCancellingError(errorMessage);
        },
        finallyCallback: () => {
          setIsCancelling(false);
        }
      })
    );
  };

  return (
    <div className={styles.unmatchedActions__container}>
      {isCancelling && (
        <ProgressBar
          label={t('inlinePlacement.messages.cancellingYourBet')}
          className={styles.unmatchedActions__progressContainer}
        />
      )}
      {!isCancelled && !isCancelling && (
        <>
          <div className={styles.unmatchedActions}>
            <Button
              className={styles.unmatchedActions__actionBtn}
              variant="tertiary"
              onClick={cancelBet}
              disabled={isLocked || isSuccessCancelled}
            >
              <Icon iconClass="fa2-delete-24" fontFamily="fa2" className={styles.unmatchedActions__actionBtn__icon} />
              {t('account.mybets.btns.cancelBet')}
            </Button>
            <Button
              variant="tertiary"
              className={styles.unmatchedActions__actionBtn}
              onClick={navigateToMarket}
              disabled={isLocked || isSuccessCancelled}
            >
              <Icon iconClass="fa2-edit-24" fontFamily="fa2" className={styles.unmatchedActions__actionBtn__icon} />
              {t('account.mybets.btns.updateBet')}
            </Button>
          </div>
          {cancellingError && (
            <Notification type="error" message={cancellingError} onClose={() => setCancellingError(null)} />
          )}
          {isLocked && (
            <Notification
              type="info"
              message={t('betslip.labels.unavailableSelectionNotification')}
              isDismissible={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UnmatchedActions;
