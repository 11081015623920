import { isNumber, toNumber } from 'lodash';

import { BetSides } from 'constants/myBets';
import { DEFAULT_EACH_WAY_DIVISOR, LINE_MARKET_ODDS } from 'constants/placement';
import { TPrice, TProfit, TSize } from 'types/bets';
import { TCalculateLiabilityOptions } from 'types/betslip';
import { getEachWayProfit } from 'utils/totalLiability';

import { isEachWayMarketType, isLineBettingType } from './betslip';

export const roundFloat = (value: number | string, precision = 2) => {
  return round(value, precision).toFixed(precision);
};

export const round = (value: number | string, precision = 2) => {
  const roundNumber = parseFloat(toNumber(value).toFixed(10));

  return Math.round(roundNumber * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const calculateLiability = (price: TPrice, size: TSize, options?: TCalculateLiabilityOptions) => {
  if (price && size) {
    const { marketType = '', bettingType = '', eachWayDivisor, betType = BetSides.Back } = options ?? {};
    const isEachWay = isEachWayMarketType(marketType);
    const isLineMarket = isLineBettingType(bettingType);
    const priceVal = isLineMarket ? LINE_MARKET_ODDS : toNumber(price);
    const sizeVal = toNumber(size);

    if (isEachWay) {
      const eachWayLiability = getEachWayProfit(
        sizeVal,
        priceVal,
        toNumber(eachWayDivisor || DEFAULT_EACH_WAY_DIVISOR),
        betType
      ).win;

      return roundFloat(Math.abs(eachWayLiability));
    }

    let liabilityValue = sizeVal * priceVal - sizeVal;
    liabilityValue = liabilityValue < 0 || isNaN(liabilityValue) ? 0 : liabilityValue;

    return roundFloat(liabilityValue);
  }

  return '';
};

export const calculatePayout = (price: TPrice, size: TSize, options?: TCalculateLiabilityOptions) => {
  if (price && size) {
    const { marketType = '', bettingType = '', eachWayDivisor, betType = BetSides.Back } = options ?? {};
    const isEachWay = isEachWayMarketType(marketType);
    const isLineMarket = isLineBettingType(bettingType);
    const priceVal = isLineMarket ? LINE_MARKET_ODDS : toNumber(price);
    const sizeVal = toNumber(size);
    const eachWayProfit = getEachWayProfit(
      sizeVal,
      priceVal,
      toNumber(eachWayDivisor || DEFAULT_EACH_WAY_DIVISOR),
      betType
    );

    if (isEachWay) {
      return roundFloat(Math.abs(eachWayProfit.win) + Math.abs(eachWayProfit.lose));
    }

    return roundFloat(sizeVal * priceVal);
  }

  return '';
};

export const calculateSize = (price: TPrice, profit: TProfit, options?: TCalculateLiabilityOptions) => {
  if (price && profit) {
    const { bettingType = '' } = options ?? {};
    const isLineMarket = isLineBettingType(bettingType);

    const priceVal = isLineMarket ? LINE_MARKET_ODDS : toNumber(price);
    const profitVal = toNumber(profit);

    let sizeValue: number | string = profitVal / (priceVal - 1);
    sizeValue = sizeValue < 0 || isNaN(sizeValue) ? '' : sizeValue;

    return sizeValue && isNumber(sizeValue) ? roundFloat(sizeValue) : '';
  }

  return '';
};
