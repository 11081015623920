import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useConvertTimeToString from 'hooks/useConvertTimeToString';

type NoInPlayContentProps = {
  marketStartTime: number;
};

const NoInPlayContent = ({ marketStartTime }: NoInPlayContentProps) => {
  const { t } = useTranslation();

  const startTimeInfo = useConvertTimeToString({ startDate: marketStartTime });

  return (
    <>
      {startTimeInfo.showDescription && (
        <span
          className={classNames({
            'biab_time-label': startTimeInfo.showHoursAndMinutes
          })}
        >
          {t(`${startTimeInfo.key}`, '', {
            min: startTimeInfo.time
          })}
          {startTimeInfo.showMonthDay ? ` ${startTimeInfo.monthDay} ` : ''}
        </span>
      )}
      {startTimeInfo.showHoursAndMinutes && <span>{startTimeInfo.hoursAndMinutes}</span>}
    </>
  );
};

export default NoInPlayContent;
