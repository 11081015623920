import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CASHOUT_HEADER_HEIGHT, ElementNames } from 'constants/app';
import { BET_LIST_URL, CASH_OUT_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { getWindowHeight } from 'redux/modules/appConfigs/selectors';
import { setElementHeight, setScrollContentHeight } from 'redux/modules/appSettings';
import { getElementHeightByName, isShowSearch } from 'redux/modules/appSettings/selectors';

interface HeaderResizeProps {
  children: ReactNode;
  isMobile: boolean;
}

const HeaderResize = ({ children }: HeaderResizeProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isShowingSearch = useSelector(isShowSearch);
  const bannerHeight = useSelector(getElementHeightByName(ElementNames.BANNER));
  const swipeMenuHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_SWIPE_MENU_HEIGHT));
  const windowHeight = useSelector(getWindowHeight);

  const [headerRef, { height }] = useElementSize<HTMLDivElement>();

  const hasNoBanner = location.pathname.startsWith(GAMES_BASE_URL);
  const isCashOutPage = location.pathname.includes(CASH_OUT_BASE_URL);
  const isBetListPage = location.pathname.includes(BET_LIST_URL);

  useEffect(() => {
    const searchBarHeight = isShowingSearch ? 30 : 0;
    const bannerHeightVal = hasNoBanner ? 0 : bannerHeight;
    const cashOutHeaderHeight = isCashOutPage ? CASHOUT_HEADER_HEIGHT : 0;
    const calculatedHeight = windowHeight - height - searchBarHeight - bannerHeightVal - cashOutHeaderHeight;

    /* For iframe there is no correct calculations, because in count takes windowHeight which is height of iframe window,
     but not browser window. In this case we need exactly browser window. Please pay attention when use
     scrollContentHeight
     */
    dispatch(setScrollContentHeight(calculatedHeight));
  }, [windowHeight, height, bannerHeight, location.pathname, isShowingSearch]);

  useEffect(() => {
    dispatch(setElementHeight({ name: ElementNames.HEADER_HEIGHT, height }));
  }, [height]);

  useEffect(() => {
    const mobileHeaderHeight = isBetListPage ? height : height + swipeMenuHeight;
    dispatch(setElementHeight({ name: ElementNames.MOBILE_HEADER_HEIGHT, height: mobileHeaderHeight }));
  }, [height, swipeMenuHeight, isBetListPage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.HEADER_HEIGHT, height: 0 }));
      dispatch(setElementHeight({ name: ElementNames.MOBILE_HEADER_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={headerRef} id="header-resize-container">
      {children}
    </div>
  );
};

export default HeaderResize;
