import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import LinkButton from 'components/LinkButton/Index';
import { ACCOUNT_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { BetListTabs, MyBetsPeriods } from 'constants/myBets';

import styles from './NoBets.module.scss';

const NoBets = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const { tab = BetListTabs.Open } = useParams();
  const isOpenTab = tab === BetListTabs.Open;

  const exchangePage = (isGamesPage && GAMES_BASE_URL) || '';
  const myBetsLink = `${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${MyBetsPeriods.Settled.toLowerCase()}/1`;

  return (
    <div className={styles.noBets__wrapper}>
      <div className={styles.noBets__content}>
        <i className={classNames('fa2 fa2-no-bets', styles.noBets__icon)} />
        <p className={styles.noBets__title}>
          {t(`account.betList.labels.${isOpenTab ? 'noOpenBets' : 'noSettledBets'}`)}
        </p>
        <p className={styles.noBets__description}>
          {t(`account.betList.labels.${isOpenTab ? 'noOpenBets' : 'noSettledBets'}.message`)}
        </p>
        <LinkButton className={styles.noBets__btn} to={isOpenTab ? HOME_BASE_URL : myBetsLink}>
          {t(`account.betList.labels.${isOpenTab ? 'goToHomepage' : 'allSettledBets'}`)}
        </LinkButton>
      </div>
    </div>
  );
};

export default NoBets;
