import { useSelector } from 'react-redux';

import { MOBILE_NOTIFICATIONS_PADDING_TOP } from 'constants/app';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getOperatorScrollY } from 'redux/modules/appSettings/selectors';
import { getAsianMobilePlacedBets } from 'redux/modules/asianViewMobileBetslip/selectors';

import AsianViewMobilePlacedBet from '../AsianViewMobilePlacedBet';

import styles from './styles.module.scss';

const AsianViewMobileBetsPlacedMessages = () => {
  const placedBets = useSelector(getAsianMobilePlacedBets);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const operatorScrollY = useSelector(getOperatorScrollY);

  const top = (isIframeEnabled ? operatorScrollY : 0) + MOBILE_NOTIFICATIONS_PADDING_TOP;

  return (
    <div className={styles.wrap} style={{ top }}>
      {placedBets.map(bet => (
        <AsianViewMobilePlacedBet bet={bet} key={`${bet.offerId}_${bet.identifier}`} />
      ))}
    </div>
  );
};

export default AsianViewMobileBetsPlacedMessages;
