import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useResizeObserver, useUnmount } from 'usehooks-ts';

import BetsTable from 'components/BetsTable';
import MobileBetDetail from 'components/BetsTable/components/MobileBetDetail';
import TableRow from 'components/BetsTable/components/TableRow';
import MobileMyBetsSkeleton from 'components/Skeleton/MobileMyBetsSkeleton';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import { CancelAllBtnStates } from 'constants/betslip';
import { MY_BETS_CONTENT_SM_WIDTH_DESKTOP, MY_BETS_PAGES_CONTENT_ID, MyBetsPeriods } from 'constants/myBets';
import useAccountPages from 'hooks/useAccountPages';
import useDevice from 'hooks/useDevice';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import useOnMountEffect from 'hooks/useOnMountEffect';
import useStickyElement from 'hooks/useStickyElement';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import {
  getDesktopSettingsAlternativeLayOdds,
  getDesktopSettingsTotalWinnings,
  getWindowWidth
} from 'redux/modules/appConfigs/selectors';
import { removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelAllBtnState, getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { hideMobileBetDetails, setCancelAllUnmatchedBetsActionId, setSortBy } from 'redux/modules/myBets';
import {
  bettingPLLoading,
  getMyBets,
  getStatementLoading,
  isShowMobileBetDetails,
  myBetsLoading
} from 'redux/modules/myBets/selectors';
import { SortByFields } from 'redux/modules/myBets/type';
import { successCancelAllBets } from 'redux/modules/placement';
import { getDescriptionColumnWidth } from 'utils/accountPages';

import styles from './MyExchangeBetsPage.module.scss';

const MyExchangeBetsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const myBetsData = useSelector(getMyBets);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isLoading = useSelector(myBetsLoading);
  const cancelAllBtnState = useSelector(getCancelAllBtnState);
  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getDesktopSettingsTotalWinnings);
  const windowWidth = useSelector(getWindowWidth);
  const isMyBetsLoading = useSelector(myBetsLoading);
  const isBettingPLLoading = useSelector(bettingPLLoading);
  const isStatementLoading = useSelector(getStatementLoading);

  const tableRef = useRef<HTMLDivElement>(null);
  const isSticky = useStickyElement(document.querySelector(`#${MY_BETS_PAGES_CONTENT_ID}`)!, tableRef);

  const { width: tableWidth } = useResizeObserver({ ref: tableRef, box: 'border-box' });
  const { isMobile } = useDevice();
  const { isAsianView } = useAccountPages();
  const { product, periodType, isCurrentPeriod } = useMyBetsFilters();

  const {
    startDate,
    endDate,
    pageSize,
    pageNumber,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber
  } = useMyBetsPagesNavigation();

  const { totalElements } = myBetsData;
  const showTableBlock = !isDetailsOpen || !isMobile;
  const showBetDetails = isDetailsOpen && isMobile;

  const isWideScreenView = windowWidth > MY_BETS_CONTENT_SM_WIDTH_DESKTOP;
  const descriptionWidth = getDescriptionColumnWidth(
    tableWidth,
    isWideScreenView,
    isCurrentPeriod,
    isAsianView,
    alternativeLayOdds,
    totalWinnings
  );

  const isDataLoading = isMyBetsLoading || isBettingPLLoading || isStatementLoading;

  useEffect(() => {
    if (cancelAllBtnState === CancelAllBtnStates.CONFIRM) {
      dispatch(setCancelAllBtnState(CancelAllBtnStates.ACTIVE));
    }

    dispatch(removeCancelActionStatus());

    return () => {
      dispatch(removeCancelActionStatus());
      dispatch(successCancelAllBets(null));
      dispatch(setCancelAllUnmatchedBetsActionId(null));
    };
  }, [startDate, endDate, periodType, product]);

  useOnMountEffect(() => {
    if (periodType === MyBetsPeriods.Open) {
      dispatch(setSortBy({ type: SortByFields.PLACED_DATE, value: 'desc' }));
    } else if (periodType === MyBetsPeriods.Settled) {
      dispatch(setSortBy({ type: SortByFields.DATE, value: 'desc' }));
    }
  });

  useUnmount(() => {
    if (showBetDetails) {
      dispatch(hideMobileBetDetails());
    }
  });

  return (
    <>
      {showBetDetails && <MobileBetDetail />}
      {showTableBlock && (
        <>
          {isMobile ? (
            <>{isLoading || isCancellingBets ? <MobileMyBetsSkeleton isShowTable /> : <BetsTable isMobile />}</>
          ) : (
            <div ref={tableRef} className={classNames(styles.skeletonContainer)}>
              {!isAsianView && alternativeLayOdds && (
                <div className={styles.infoMsg}>{t('account.mybets.messages.alternativeLayOdds')}</div>
              )}
              <TableRow isHeader isSticky={isSticky} isDisabled={!totalElements || isDataLoading} />
              {(isLoading || isCancellingBets) && <MyBetsSkeleton isShowTable isAddColumn={!isCurrentPeriod} />}
              {!isLoading && !isCancellingBets && (
                <BetsTable tableWidth={tableWidth} descriptionWidth={descriptionWidth} />
              )}
            </div>
          )}
          {!isLoading && !!totalElements && !isCancellingBets && (
            <Pagination
              source={myBetsData}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={handleSetPageSize}
              setPageNumber={handleSetPageNumber}
              setPageSizeAndNumber={handleSetPageSizeAndNumber}
            />
          )}
        </>
      )}
    </>
  );
};

export default MyExchangeBetsPage;
