import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useUnmount } from 'usehooks-ts';

import MobileAccountNavigation from 'components/MobileAccountNavigation';
import ResponsibleBettingTimeOutAlert from 'components/ResponsibleBetting/components/ResponsibleBettingTimeOutAlert';
import { CookieNames, ElementNames } from 'constants/app';
import {
  BetsTypesByPeriods,
  DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME,
  ExchangeTypes,
  mapAccountPageToBetsType,
  MIN_ITEMS_PAGINATION,
  NavigationButtons,
  PLTabButtons
} from 'constants/myBets';
import { MyBetsPages as MyBetsPagesMap } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import useOnMountEffect from 'hooks/useOnMountEffect';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getCurrency, getCurrencyCode } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  fetchAccountProducts,
  resetAccountProducts,
  resetAccountStatementTransactionTypes,
  setMultiCurrencyValue
} from 'redux/modules/myBets';
import {
  getAreProductsLoaded,
  getIsMobileFiltersOpen,
  getMyBetsMultiCurrencyValue
} from 'redux/modules/myBets/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { BetType, MyBetsPeriod, PLBetType } from 'types/myBets';
import { getMultiCurrencyCode } from 'utils/myBetsValues';

import MobileAccountFilters from './components/MobileAccountFilters';
import MyBetsPagesContent from './components/MyBetsPagesContent/MyBetsPagesContent';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './MyBetsPages.module.scss';

const MyBetsPages = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.MY_BETS_SEARCH,
    CookieNames.MY_BETS_TYPES,
    CookieNames.EXCHANGE_TYPE_NEW
  ]);
  const { periodType } = useParams();
  const navigate = useNavigate();

  const currencyCode = useSelector(getCurrencyCode);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);
  const isMobileFiltersOpen = useSelector(getIsMobileFiltersOpen);
  const areProductsLoaded = useSelector(getAreProductsLoaded);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const currency = useSelector(getCurrency);
  const currencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMobile } = useDevice();
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const {
    pageNumber,
    getStatementData: fetchStatementData,
    getPLData: fetchPLData,
    getBetData: fetchMyBets
  } = useMyBetsPagesNavigation();
  const { selectedTab } = useAccountUrlParams();
  const { isAsianViewEnabled, isSportsEnabled, isGamesEnabled, availableProducts } = useAccountProducts();
  const { product } = useMyBetsFilters();

  const betsType = mapAccountPageToBetsType[selectedTab];
  const isProductsResetted = useRef(false);
  const activePLTab = (cookies.PROFITANDLOSS_BETTYPE as PLBetType) || PLTabButtons[0];

  useOnMountEffect(() => {
    if (isMultiCurrencySupported && currency.currencyCode && selectedTab !== NavigationButtons.MyBets) {
      dispatch(setMultiCurrencyValue(currency.currencyCode));
    }
  });

  useUpdateEffect(() => {
    if (isMultiCurrencySupported) {
      dispatch(
        setMultiCurrencyValue(
          currency.currencyCode && selectedTab !== NavigationButtons.MyBets ? currency.currencyCode : null
        )
      );
    }
  }, [selectedTab]);

  useUnmount(() => {
    dispatch(setMultiCurrencyValue(null));
  });

  useEffect(() => {
    if (currencyCode && selectedTab) {
      dispatch(
        fetchAccountProducts({
          currency: currencyCode,
          betsType: betsType,
          onFinishCallback: () => {
            isProductsResetted.current = false;
          }
        })
      );
    }

    return () => {
      dispatch(resetAccountProducts());
      isProductsResetted.current = true;
    };
  }, [selectedTab, currencyCode]);

  useEffect(() => {
    if (currencyCode && areProductsLoaded && selectedTab && !isProductsResetted.current) {
      const multiCurrencyCode = getMultiCurrencyCode(
        currencyCode,
        isMultiCurrencySupported,
        MyBetsPagesMap.AccountStatement
      );

      if (selectedTab === NavigationButtons.MyBets) {
        let newBetTypes: BetType[] = [];

        if (
          !cookies.BIAB_MY_BETS_TYPES ||
          !cookies.BIAB_MY_BETS_TYPES.length ||
          (cookies.BIAB_MY_BETS_TYPES.length &&
            periodType &&
            !(BetsTypesByPeriods[capitalize(periodType) as MyBetsPeriod] as string[]).includes(
              cookies.BIAB_MY_BETS_TYPES[0]
            ))
        ) {
          setCookie(CookieNames.MY_BETS_TYPES, BetsTypesByPeriods[capitalize(periodType) as MyBetsPeriod], {
            path: '/'
          });

          newBetTypes = BetsTypesByPeriods[capitalize(periodType) as MyBetsPeriod];
        }

        fetchMyBets({
          number: 0,
          search: cookies.BIAB_MY_BETS_SEARCH,
          currency: currencyValue || multiCurrencyCode,
          betsTypes: newBetTypes.length
            ? newBetTypes
            : cookies.BIAB_MY_BETS_TYPES?.length
            ? undefined
            : BetsTypesByPeriods[capitalize(periodType) as MyBetsPeriod]
        });
      }

      if (selectedTab === NavigationButtons.ProfitAndLoss) {
        fetchPLData({ currency: currencyValue || multiCurrencyCode, number: 0 });
      }

      if (selectedTab === NavigationButtons.Statement) {
        const pageNumberFromUrl = parseInt(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));

        if (pageNumberFromUrl - 1 !== pageNumber) {
          fetchStatementData({
            number: pageNumberFromUrl - 1,
            currency: currencyValue || multiCurrencyCode,
            size: MIN_ITEMS_PAGINATION
          });
        } else {
          fetchStatementData({ currency: currencyValue || multiCurrencyCode, size: MIN_ITEMS_PAGINATION });
        }
      }
    }
  }, [selectedTab, currencyCode, areProductsLoaded]);

  useEffect(() => {
    if (selectedTab === NavigationButtons.MyBets) {
      const pageNumberFromUrl = parseInt(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));

      if (pageNumberFromUrl && pageNumberFromUrl > 1 && isLoggedIn) {
        const urlArr = location.pathname.split('/');
        urlArr[urlArr.length - 1] = '1';
        navigate(urlArr.join('/'));
      }
    }

    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
      dispatch(resetAccountStatementTransactionTypes());

      localStorage.removeItem(DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME);
    };
  }, []);

  useEffect(() => {
    if (selectedTab === NavigationButtons.MyBets) {
      if (
        isAsianViewEnabled &&
        !isSportsEnabled &&
        !isGamesEnabled &&
        cookies.EXCHANGE_TYPE_NEW !== ExchangeTypes.AsianView &&
        (!product || (product !== ExchangeTypes.AsianView && !availableProducts.includes(product)))
      ) {
        setCookie(CookieNames.EXCHANGE_TYPE_NEW, ExchangeTypes.AsianView, { path: '/' });
      } else if (
        !isAsianViewEnabled &&
        (isSportsEnabled || isGamesEnabled) &&
        cookies.EXCHANGE_TYPE_NEW !== ExchangeTypes.SportsGames &&
        (!product || (product !== ExchangeTypes.SportsGames && !availableProducts.includes(product)))
      ) {
        setCookie(CookieNames.EXCHANGE_TYPE_NEW, ExchangeTypes.SportsGames, { path: '/' });
      } else if ((isAsianViewEnabled || isSportsEnabled || isGamesEnabled) && !product) {
        setCookie(CookieNames.EXCHANGE_TYPE_NEW, availableProducts[0], { path: '/' });
      }
    }
  }, [isAsianViewEnabled, isSportsEnabled, isGamesEnabled, product]);

  return (
    <div
      className={classNames('biab_account-page', styles.betsPagesContainer, {
        [styles.betsPagesContainer__mobile]: isMobile
      })}
    >
      {isTimeOutEnabled && isMobile && (
        <div className={styles.betsPagesContainer__alert}>
          <ResponsibleBettingTimeOutAlert />
        </div>
      )}
      {isMobile && !isMobileFiltersOpen && <MobileAccountNavigation />}
      {isMobile && isMobileFiltersOpen ? (
        <MobileAccountFilters selectedTab={selectedTab} />
      ) : (
        <MyBetsPagesContent selectedTab={selectedTab} activePLTab={activePLTab} />
      )}
    </div>
  );
};

export default MyBetsPages;
