import { useSelector } from 'react-redux';
import classNames from 'classnames';

import StatementTable from 'components/StatementTable';
import AccountStatementItemMobileDetails from 'components/StatementTable/components/AccountStatementItemMobileDetails/AccountStatementItemMobileDetails';
import { MIN_ITEMS_PAGINATION } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import {
  getIsLoadingProducts,
  getIsMobileStatementItemDetails,
  getStatementData,
  getStatementLoading
} from 'redux/modules/myBets/selectors';
import { iosBundleRedirect } from 'utils';

import styles from './AccountStatementPage.module.scss';

const AccountStatementPage = () => {
  const statementData = useSelector(getStatementData);
  const isLoading = useSelector(getStatementLoading);
  const isMobileStatementItemDetails = useSelector(getIsMobileStatementItemDetails);
  const isLoadingProducts = useSelector(getIsLoadingProducts);

  const { content, totalElements } = statementData;

  const { pageSize, pageNumber, handleSetPageNumber, handleSetPageSize, handleSetPageSizeAndNumber } =
    useMyBetsPagesNavigation();
  const { isMobile } = useDevice();

  return (
    <>
      {isMobile ? (
        <>
          {isMobileStatementItemDetails ? (
            <AccountStatementItemMobileDetails />
          ) : (
            <div
              className={classNames(styles.tableContainer, {
                [styles.tableContainer__mobile]: !iosBundleRedirect
              })}
            >
              <StatementTable content={content} />
            </div>
          )}
        </>
      ) : (
        <div className={classNames('biab_my-bets-table-header', styles.tableContainer)}>
          <StatementTable content={content} />
        </div>
      )}
      {!isLoading &&
        !isLoadingProducts &&
        totalElements > MIN_ITEMS_PAGINATION &&
        (!isMobile || !isMobileStatementItemDetails) && (
          <Pagination
            source={statementData}
            pageSize={pageSize}
            setPageSize={handleSetPageSize}
            pageNumber={pageNumber}
            setPageNumber={handleSetPageNumber}
            setPageSizeAndNumber={handleSetPageSizeAndNumber}
          />
        )}
    </>
  );
};

export default AccountStatementPage;
