import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import VirtualKeyboard from 'components/CashOutButton/components/CashOutState/partials/VirtualKeyboard';
import { asianViewComponents, componentsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import {
  getCurrency,
  getDisplayCurrencySymbol,
  getIndianNumberSystemEnabled,
  getIsCurrencySymbolAfterAmount
} from 'redux/modules/appConfigs/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getIsUserAccountSettings,
  getPrecisionType,
  getUserAccountSettingsDefaultStakes
} from 'redux/modules/user/selectors';
import { Stake } from 'redux/modules/user/type';
import { DefaultStakeStates } from 'types/navigation';
import { getDefaultStakeValidationValue, getStakeDefaultValue, getStakeValue } from 'utils/navigation';
import { getAsianViewStakeAmountLabelInfo, getStakeAmountLabelInfo } from 'utils/settings';

import styles from './styles.module.scss';

type DefaultStakeFormProps = {
  isAsianView?: boolean;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isCouponSettings?: boolean;
  couponSettingsDefaultStakes?: (Stake | null)[];
  setCouponSettingsDefaultStakes?: Dispatch<SetStateAction<(Stake | null)[]>>;
};

const DefaultStakeForm = ({
  isEditMode,
  setIsEditMode,
  isAsianView = false,
  isCouponSettings = false,
  couponSettingsDefaultStakes,
  setCouponSettingsDefaultStakes
}: DefaultStakeFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const currency = useSelector(getCurrency);
  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const currencySymbolAfterAmount = useSelector(getIsCurrencySymbolAfterAmount);
  const indianNumberSystemEnabled = useSelector(getIndianNumberSystemEnabled);
  const precision = useSelector(getPrecisionType);
  const defaultStakes = useSelector(getUserAccountSettingsDefaultStakes);

  const initialDefaultStakesStates: DefaultStakeStates = {
    first: {
      value: getStakeValue(isCouponSettings ? couponSettingsDefaultStakes?.[0] : defaultStakes?.[0]),
      defaultValue: getStakeDefaultValue(isCouponSettings ? couponSettingsDefaultStakes?.[0] : defaultStakes?.[0]),
      error: getDefaultStakeValidationValue({
        value: isCouponSettings ? couponSettingsDefaultStakes?.[0]?.value : defaultStakes?.[0]?.value,
        currency,
        t
      }),
      index: 0,
      key: 'first',
      isFocused: false
    },
    second: {
      value: getStakeValue(isCouponSettings ? couponSettingsDefaultStakes?.[1] : defaultStakes?.[1]),
      defaultValue: getStakeDefaultValue(isCouponSettings ? couponSettingsDefaultStakes?.[1] : defaultStakes?.[1]),
      error: getDefaultStakeValidationValue({
        value: isCouponSettings ? couponSettingsDefaultStakes?.[1]?.value : defaultStakes?.[1]?.value,
        currency,
        t
      }),
      index: 1,
      key: 'second',
      isFocused: false
    },
    third: {
      value: getStakeValue(isCouponSettings ? couponSettingsDefaultStakes?.[2] : defaultStakes?.[2]),
      defaultValue: getStakeDefaultValue(isCouponSettings ? couponSettingsDefaultStakes?.[2] : defaultStakes?.[2]),
      error: getDefaultStakeValidationValue({
        value: isCouponSettings ? couponSettingsDefaultStakes?.[2]?.value : defaultStakes?.[2]?.value,
        currency,
        t
      }),
      index: 2,
      key: 'third',
      isFocused: false
    }
  };

  const [defaultStakesStates, setDefaultStakesStates] = useState<DefaultStakeStates>(initialDefaultStakesStates);

  const { isMobile, isDesktop } = useDevice();

  const isDefaultStakes = !!defaultStakes.length;
  const { states, isSelectedStakeError, noValues, isError, focusedInputKey } = useMemo(() => {
    const statesValues = Object.values(defaultStakesStates);

    return {
      states: statesValues,
      isSelectedStakeError: statesValues.find(stake => stake.defaultValue)?.error || '',
      noValues: statesValues.every(({ value }) => !value),
      isError: statesValues.some(({ error }) => !!error),
      focusedInputKey: statesValues.find(({ isFocused }) => isFocused)?.key
    };
  }, [defaultStakesStates]);

  const isValuesChanged =
    initialDefaultStakesStates.first.value !== defaultStakesStates.first.value ||
    initialDefaultStakesStates.second.value !== defaultStakesStates.second.value ||
    initialDefaultStakesStates.third.value !== defaultStakesStates.third.value;

  const handleChangeDefaultStake = ({ key, value }: { key?: 'first' | 'second' | 'third'; value: string }) => {
    if (key && value !== '.' && !/\..*\./.test(value) && value[0] !== '.') {
      if (value === '') {
        let errorMessage = '';
        const noOtherValues = states.filter(state => state.key !== key).every(state => !state.value);

        if (noOtherValues) {
          errorMessage = t('account.settings.betting.validation.empty');
        }

        if (key === 'first') {
          setDefaultStakesStates(prevState => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              value,
              defaultValue: false,
              error: errorMessage
            }
          }));
        } else {
          setDefaultStakesStates(prevState => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              value,
              defaultValue: false
            },
            first: {
              ...prevState.first,
              error: errorMessage
            }
          }));
        }
      } else if (/^[0-9.\b]+$/.test(value)) {
        setDefaultStakesStates(prevState => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            value,
            error: getDefaultStakeValidationValue({ value, t, currency })
          }
        }));
      } else {
        if (noValues) {
          setDefaultStakesStates(prevState => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              error: t('account.settings.betting.validation.empty')
            }
          }));
        }
      }
    }
  };

  const handleClickDefaultStake = (index: number, value = '') => {
    let newDefaultStakes: (Stake | null)[];

    if (isCouponSettings && couponSettingsDefaultStakes !== undefined) {
      newDefaultStakes = defaultStakes.map((_, stakeIndex) => {
        if (stakeIndex === index) {
          return { ...couponSettingsDefaultStakes[stakeIndex], defaultValue: true } as Stake;
        } else if (couponSettingsDefaultStakes[stakeIndex]?.defaultValue) {
          return { ...couponSettingsDefaultStakes[stakeIndex], defaultValue: false } as Stake;
        }

        return couponSettingsDefaultStakes[stakeIndex];
      });
    } else {
      newDefaultStakes = defaultStakes.map((stake, stakeIndex) => {
        if (stakeIndex === index) {
          return { ...stake, defaultValue: true } as Stake;
        } else if (stake?.defaultValue) {
          return { ...stake, defaultValue: false } as Stake;
        }

        return stake;
      });
    }

    if (isCouponSettings && setCouponSettingsDefaultStakes) {
      setCouponSettingsDefaultStakes(newDefaultStakes);
    } else if (isAccountSettings && value) {
      dispatch(
        setSettings({
          settings: {
            defaultStakes: newDefaultStakes
          }
        })
      );
    }
  };

  const handleSaveStakes = () => {
    if (!isError && !noValues && isAccountSettings && isValuesChanged) {
      const noChangedStakes = defaultStakes.slice(3);
      const onlyOneValue = states.filter(({ value }) => !!value).length === 1;
      const prevDefStakes = defaultStakes.slice(0, 3);
      const noPrevValues = prevDefStakes.every(stake => stake === null);
      const stakesToSubmit = states
        .sort((a, b) => a.index - b.index)
        .map(({ value, index, defaultValue }) => {
          if (!value) {
            return null;
          }

          let defValue: boolean;

          if (noPrevValues) {
            defValue = index === 0;
          } else if (onlyOneValue) {
            defValue = true;
          } else {
            defValue = defaultValue;
          }

          return {
            value: Number(value),
            defaultValue: defValue
          } as Stake;
        });

      if (isCouponSettings && setCouponSettingsDefaultStakes) {
        setCouponSettingsDefaultStakes(stakesToSubmit);
      } else {
        dispatch(
          setSettings({
            settings: {
              defaultStakes: stakesToSubmit.concat(noChangedStakes)
            }
          })
        );
      }
      setIsEditMode(false);
    }
  };

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    setDefaultStakesStates(initialDefaultStakesStates);
  };

  const getStakeAmountLabel = (stakeAmount: number) => {
    const { label, translationKey } = isAsianView
      ? getAsianViewStakeAmountLabelInfo({ stakeAmount })
      : getStakeAmountLabelInfo(stakeAmount, precision, indianNumberSystemEnabled);
    const formatSymbol = translationKey ? t(translationKey) : '';

    if (displayCurrencySymbol) {
      if (currencySymbolAfterAmount) {
        return `${label}${formatSymbol}${currency.symbol}`;
      }

      return `${currency.symbol}${label}${formatSymbol}`;
    }

    return `${label}${formatSymbol}`;
  };

  const handleFocusStake = (key: 'first' | 'second' | 'third') => {
    if (key === 'first') {
      setDefaultStakesStates(prevState => ({
        first: { ...prevState.first, isFocused: true },
        second: { ...prevState.second, isFocused: false },
        third: { ...prevState.third, isFocused: false }
      }));
    } else if (key === 'second') {
      setDefaultStakesStates(prevState => ({
        first: { ...prevState.first, isFocused: false },
        second: { ...prevState.second, isFocused: true },
        third: { ...prevState.third, isFocused: false }
      }));
    } else {
      setDefaultStakesStates(prevState => ({
        first: { ...prevState.first, isFocused: false },
        second: { ...prevState.second, isFocused: false },
        third: { ...prevState.third, isFocused: true }
      }));
    }
  };

  useEffect(() => {
    if (isMobile && isDefaultStakes) {
      setDefaultStakesStates(prevState => ({
        first: { ...prevState.first, value: getStakeValue(defaultStakes?.[0]) },
        second: { ...prevState.second, value: getStakeValue(defaultStakes?.[1]) },
        third: { ...prevState.third, value: getStakeValue(defaultStakes?.[2]) }
      }));
    }
  }, [isDefaultStakes]);

  return (
    <>
      <div
        className={classNames('biab_default-stakes', styles.stake__container, {
          [styles.stake__container__asianView]: isAsianView,
          [styles.stake__container__asianView__edit]: isAsianView && isEditMode,
          [styles.stake__container__asianView__mobile]: isAsianView && isMobile
        })}
      >
        <div
          className={classNames(styles.stake__inner, {
            [styles.stake__inner__asianViewMobile]: isAsianView && isMobile
          })}
        >
          {defaultStakes?.slice(0, 3)?.map((defaultStake, index) => {
            const defaultStakeState = states.find(stake => stake.index === index);
            const isDisabled = defaultStakeState?.error || isSelectedStakeError;
            const isDefaultValue = isCouponSettings
              ? !!couponSettingsDefaultStakes?.[index]?.defaultValue
              : !!defaultStake?.defaultValue;
            const defaultStakeValue = isCouponSettings
              ? couponSettingsDefaultStakes?.[index]?.value
              : defaultStake?.value;

            return (
              <div
                key={index}
                className={classNames(styles.stake__stakeWrapper, {
                  ['biab_default-stake']: !isAsianView,
                  [componentsBranding.TERTIARY_BTN]: isAsianView,
                  [componentsBranding.SELECTED]: isDefaultValue,
                  biab_active: isDefaultValue,
                  biab_error: defaultStakeState?.error,
                  [styles.stake__stakeWrapper__asianViewMobile]: isAsianView && isMobile
                })}
              >
                {!isEditMode && (
                  <span
                    className={classNames(styles.stake__item, {
                      [styles.stake__item__mobile]: isMobile,
                      [styles.stake__disable]: isDesktop && isDisabled,
                      [styles.stake__item__asianView]: isAsianView,
                      [styles.stake__item__asianView__mobile]: isAsianView && isMobile,
                      [styles.stake__item__asianView__noEdit]: isAsianView && !isEditMode,
                      [styles.stake__item__asianView__active]: isAsianView && isDefaultValue && !isEditMode,
                      [styles.stake__item__asianView__mobile__error]:
                        isAsianView && isMobile && !isEditMode && defaultStakeState?.error
                    })}
                    onClick={!isDisabled ? () => handleClickDefaultStake(index, defaultStakeState?.value) : undefined}
                  >
                    {defaultStakeValue ? `${getStakeAmountLabel(defaultStakeValue)}` : null}
                  </span>
                )}
                {isEditMode && (
                  <input
                    type="text"
                    name="defaultStakes"
                    className={classNames('biab_b-form-input biab_xs', styles.stake__stakeInput, {
                      [styles.stake__stakeInput__mobile]: isMobile,
                      [styles.stake__stakeInput__asianView]: isAsianView,
                      [styles.stake__stakeInput__asianView__mobile]: isAsianView && isMobile,
                      [styles.stake__stakeInput__asianView__couponSettings__focused]:
                        isAsianView && isCouponSettings && defaultStakeState?.isFocused,
                      [styles.stake__stakeInput__asianView__couponSettings__error]:
                        isAsianView && isMobile && defaultStakeState?.error
                    })}
                    value={defaultStakeState?.value ?? ''}
                    onChange={e =>
                      handleChangeDefaultStake({ key: defaultStakeState?.key ?? 'first', value: e.target.value })
                    }
                    onFocus={() => handleFocusStake(defaultStakeState?.key ?? 'first')}
                    readOnly={isAsianView && isCouponSettings}
                  />
                )}
                {defaultStakeState?.error && (
                  <p className={classNames('biab_error-msg', styles.stake__error)}>{t(defaultStakeState.error)}</p>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={classNames('biab_default-stakes-action', styles.stake__actions, {
            [styles.stake__actions__edit]: isEditMode && isAsianView,
            [styles.stake__actions__noEditAsianViewMobile]: isMobile && isAsianView && !isEditMode,
            [styles.stake__actions__editAsianViewMobile]: isMobile && isAsianView && isEditMode
          })}
        >
          {!isEditMode && (
            <button
              className={classNames(
                'biab_btn-info biab_btn-edit',
                styles.stake__button,
                asianViewComponents.SECONDARY_BTN,
                {
                  'biab_b-form-button': !(isAsianView && isMobile),
                  [styles.stake__button__asianView]: isAsianView,
                  [styles.stake__button__mobile]: isMobile,
                  [styles.stake__button__mobile__asianView]: isMobile && isAsianView
                }
              )}
              onClick={() => setIsEditMode(true)}
            >
              {t('account.settings.betting.buttons.edit')}
            </button>
          )}
          {isEditMode && (
            <>
              <button
                onClick={handleSaveStakes}
                disabled={noValues || isError || !isValuesChanged}
                className={classNames(
                  'biab_btn-info biab_btn-save',
                  styles.stake__button,
                  asianViewComponents.PRIMARY_BTN,
                  {
                    'biab_b-form-button': !(isAsianView && isMobile),
                    biab_disabled: noValues || isError || !isValuesChanged,
                    [styles.stake__button__mobile]: isMobile,
                    [styles.stake__button__asianView]: isAsianView,
                    [styles.stake__button__mobile__asianView]: isMobile && isAsianView,
                    [styles.stake__button__mobile__asianView__disabled]:
                      isMobile && isAsianView && (noValues || isError || !isValuesChanged),
                    [styles.stake__button__mobile__asianView__save]: isMobile && isAsianView
                  }
                )}
              >
                {t('account.settings.betting.buttons.save')}
              </button>
              <button
                onClick={handleCancelEditMode}
                className={classNames(
                  'biab_btn-default biab_btn-cancel',
                  styles.stake__button,
                  styles.stake__button__cancel,
                  asianViewComponents.SECONDARY_BTN,
                  {
                    'biab_b-form-button': !(isAsianView && isMobile),
                    [styles.stake__button__mobile]: isMobile,
                    [styles.stake__button__asianView]: isAsianView,
                    [styles.stake__button__mobile__asianView]: isMobile && isAsianView
                  }
                )}
              >
                {t('account.settings.betting.buttons.cancel')}
              </button>
            </>
          )}
        </div>
      </div>
      {isAsianView && isCouponSettings && isEditMode && (
        <VirtualKeyboard
          onKeyClick={value =>
            handleChangeDefaultStake({
              key: focusedInputKey,
              value: focusedInputKey ? defaultStakesStates[focusedInputKey].value + value : ''
            })
          }
          onBackSpace={() =>
            handleChangeDefaultStake({
              key: focusedInputKey,
              value: focusedInputKey
                ? defaultStakesStates[focusedInputKey].value.slice(
                    0,
                    defaultStakesStates[focusedInputKey].value.length - 1
                  )
                : ''
            })
          }
          isWidthBorder
          threeRows
          isCouponSettings
        />
      )}
    </>
  );
};

export default DefaultStakeForm;
