import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { ReactComponent as DoubleChevron } from 'assets/icons/DoubleChevron.svg';
import RowRunner from 'components/RowRunner';
import WhatIf from 'components/WhatIf';
import { PageBlocks, PlacementPages } from 'constants/app';
import { ASIAN_BASE_URL } from 'constants/locations';
import useCellConfigs from 'hooks/useCellConfigs';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLayColumn from 'hooks/useLayColumn';
import { getLanguage, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import {
  getMarketPricesCurrency,
  getMarketPricesId,
  getMatchedAmountByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { IMarket, TMarketRunner } from 'types/markets';

import styles from './styles.module.scss';

interface IShowHideRunners {
  market: IMarket;
  isCashOut?: boolean;
  isAsianView?: boolean;
  isEvent?: boolean;
}

const runnerButtonLabel = (isAsianView: boolean, visible: boolean) => {
  if (isAsianView) {
    return visible ? 'asianView.labels.cashout.hideRunners' : 'asianView.labels.cashout.showRunners';
  }
  return visible ? 'cashout.page.labels.hideAllRunners' : 'cashout.page.labels.showAllRunners';
};

const ShowHideRunners = ({ market, isCashOut, isAsianView = false, isEvent = false }: IShowHideRunners) => {
  const { t } = useTranslation();
  const location = useLocation();

  const language = useSelector(getLanguage);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketCurrency = useSelector(getMarketPricesCurrency(market.marketId));
  const marketPricesId = useSelector(getMarketPricesId(market.marketId));
  const marketMatchedAmount = useSelector(getMatchedAmountByMarketPricesId(market.marketId));

  const { isLandscape } = useDevice();

  const [visible, setVisible] = useState(false);

  // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
  // const isAVDesktopCashOut = isDesktop && isAsianView && !!isCashOut;
  // const uniqueId = `${market.marketId}__${market.runners[0].selectionId ?? ''}__${market.runners[0].handicap ?? 0}`;
  // const isDesktopAsianViewCashOutRunnersOpen = useSelector(
  //   getIsAsianViewCashOutDesktopRunnersOpenedById(isAVDesktopCashOut ? uniqueId : undefined)
  // );
  // const isFirstRender = useRef(true);
  // const visible = isAVDesktopCashOut ? isDesktopAsianViewCashOutRunnersOpen : visible;

  const { isLayColumnEnabled } = useLayColumn({
    sportId: market.eventType.id,
    marketId: market.marketId,
    bettingType: market.description.bettingType
  });

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);

  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount: 1,
    isMarketView: true,
    addMobileExtraSpace: true
  });

  const { formattedAmount: formatMatched, noFormattedAmount: tooltipFormatMatched } = useFormatCurrency(
    marketMatchedAmount || 0,
    marketCurrency,
    {
      noCommas: true
    }
  );

  const getRunnerName = (runner: TMarketRunner) => {
    if (runner.runnerNameTranslations) {
      return runner.runnerNameTranslations[language] || runner.runnerName;
    }
    return runner.runnerName;
  };

  // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //   } else {
  //     // this effect and timeout needed to remove market prices data after Runners is closed, because if do it on click
  //     // in handleToggleRunners - you will face with issue when you removed data, but after few milliseconds connection
  //     // is still open with previous marketIds and you will receive and store marketPrices data in redux, but need to be
  //     // sure that store is clean to not show user previous data (to avoid jumping with different values, when we show
  //     // what stored in redux, and then value will be updated immediately after new marketPrices data is received when
  //     // runners is opened)
  //     setTimeout(() => {
  //       if (!isDesktopAsianViewCashOutRunnersOpen && isAVDesktopCashOut) {
  //         dispatch(removeMarketPricesByMarketId(market.marketId));
  //       }
  //     }, 500);
  //   }
  // }, [isDesktopAsianViewCashOutRunnersOpen]);

  if (!market.runners.length) {
    return null;
  }

  const handleToggleRunners = () => {
    setVisible(prevState => !prevState);

    // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
    // if (isAVDesktopCashOut) {
    //   if (isDesktopAsianViewCashOutRunnersOpen) {
    //     dispatch(closeDesktopRunners(uniqueId));
    //   } else {
    //     dispatch(openDesktopRunners(uniqueId));
    //   }
    // } else {
    //   setVisible(prevState => !prevState);
    // }
  };

  if (isCashOut && !!market.runners.length) {
    return (
      <>
        <div className={classNames('markets', styles.markets, styles.cashOutMarket)}>
          {visible &&
            market.runners.map(runner => {
              return (
                <div className={styles.cashOutMarket__runner} key={`${runner.runnerName}-${runner.selectionId}`}>
                  <div className={styles.cashOutMarket__runnerName}>{getRunnerName(runner)}</div>
                  {marketPricesId && (
                    <WhatIf
                      marketId={market.marketId}
                      commission={market.commission}
                      eachWayDivisor={market.description?.eachWayDivisor}
                      marketType={market.description?.marketType}
                      selectionId={runner.selectionId}
                      handicap={runner.handicap}
                      isAsianView={isAsianView}
                      isEvent={isEvent}
                      pageBlock={PageBlocks.CASH_OUT}
                    />
                  )}
                </div>
              );
            })}
        </div>
        <div className={styles.main}>
          <span className={classNames(`biab_toggle${visible ?? '_opened'}`, styles.link)} onClick={handleToggleRunners}>
            {t(runnerButtonLabel(isAsianViewPage, visible))}
            {isAsianViewPage ? (
              <DoubleChevron
                className={classNames(styles.main__chevronDown, {
                  [styles.main____chevronDownClose]: !visible
                })}
              />
            ) : (
              <i
                className={classNames('fa2 biab_expand', {
                  'fa2-arrow-up': visible,
                  'fa2-arrow-down': !visible
                })}
              />
            )}
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      {!!market.runners.length && visible && (
        <div className={classNames('markets', styles.markets)}>
          <div className={styles.matched}>
            <span
              data-tooltip-id="tooltip"
              data-tooltip-html={unescape(t('tooltip.matchedTotal', { amount: tooltipFormatMatched }))}
              className="market-title"
            >
              {t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: <span>{formatMatched}</span>
            </span>
            <div className={classNames(styles.tabs)}>
              <span
                data-tooltip-id="tooltip"
                data-tooltip-html={unescape(t('tooltip.backColumn'))}
                className="market-title"
              >
                {t('market.back')}
              </span>
              {isLayColumnEnabled && (
                <span
                  data-tooltip-id="tooltip"
                  data-tooltip-html={unescape(t('tooltip.layColumn'))}
                  className="market-title"
                >
                  {t('market.lay')}
                </span>
              )}
            </div>
          </div>
          <div className={styles.runners}>
            {market.runners.map(runner => (
              <RowRunner
                key={runner.selectionId}
                market={market}
                runner={runner}
                isDepthEnabled={false}
                pageBlock={PageBlocks.CASH_OUT}
                isLandscape={isLandscape}
                page={PlacementPages.CASH_OUT}
                desktopCellsContainerWidth={desktopCellsContainerWidth}
                mobileCellsContainerWidth={mobileCellsContainerWidth}
              />
            ))}
          </div>
        </div>
      )}
      <div className={styles.main}>
        <span className={classNames(`biab_toggle${visible ?? '_opened'}`, styles.link)} onClick={handleToggleRunners}>
          {t(runnerButtonLabel(isAsianViewPage, visible))}
          {isAsianViewPage ? (
            <DoubleChevron
              className={classNames(styles.main__chevronDown, {
                [styles.main____chevronDownClose]: !visible
              })}
            />
          ) : (
            <i
              className={classNames('fa2 biab_expand', {
                'fa2-arrow-up': visible,
                'fa2-arrow-down': !visible
              })}
            />
          )}
        </span>
      </div>
    </>
  );
};

export default ShowHideRunners;
