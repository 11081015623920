import { TFunction } from 'react-i18next';

import { RunnersStatuses } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { TAsianViewMarket, TAsianViewRunner } from 'redux/modules/asianView/type';
import { TMarketPricesRunner } from 'types/markets';

const formatDoubleLineHandicap = (value: number) => {
  const isNegative = +value < 0;
  const positiveValue = isNegative ? +value * -1 : +value;
  const handicap = positiveValue && Number.isInteger(positiveValue) ? positiveValue.toFixed(1) : positiveValue;

  return handicap.toString();
};

export const getAVRunnerName = ({
  runner,
  index,
  market,
  isSingleLine,
  firstKeyLineId,
  secondKeyLineId,
  isKeyLine,
  firstKeyLineAdjHandicap,
  secondKeyLineAdjHandicap,
  firstKeyLineHandicap,
  secondKeyLineHandicap,
  t
}: {
  runner: TAsianViewRunner;
  index: number;
  market: TAsianViewMarket;
  isSingleLine: boolean;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
  isKeyLine: boolean;
  t: TFunction<'translation', undefined>;
  firstKeyLineAdjHandicap?: number | null;
  secondKeyLineAdjHandicap?: number | null;
  firstKeyLineHandicap?: number;
  secondKeyLineHandicap?: number;
}) => {
  if (market.marketType.includes(MARKET_TYPES.firstHalfGoals) && index === 0) {
    return (
      parseFloat(market.marketType.replace(`${MARKET_TYPES.firstHalfGoals}_`, '').replace('.', '')) * 0.1
    ).toString();
  }
  if (market.marketType === MARKET_TYPES.altTotalGoals && index === 0) {
    return formatDoubleLineHandicap(runner.handicap);
  }
  if (market.marketType === MARKET_TYPES.asianHandicap) {
    let handicap = runner.handicap;
    const isKeyLineByIndex = index < 2 ? (index === 0 ? firstKeyLineId : secondKeyLineId) : false;

    if (isKeyLine && isKeyLineByIndex) {
      const ahc = index === 0 ? firstKeyLineAdjHandicap : secondKeyLineAdjHandicap;

      if (ahc !== undefined && ahc !== null) {
        const handicapByIndex = (index == 0 ? firstKeyLineHandicap : secondKeyLineHandicap) || 0;

        handicap = handicap + (ahc - handicapByIndex);
      }
    }
    if (isSingleLine) {
      if (index === 0) {
        return formatDoubleLineHandicap(handicap);
      }
    } else {
      if (index === 0) {
        return handicap <= 0 ? formatDoubleLineHandicap(handicap) : '';
      } else {
        return handicap < 0 ? formatDoubleLineHandicap(handicap) : '';
      }
    }
  }
  return isSingleLine
    ? ''
    : ['H', 'A', 'D', 'O', 'U'].includes(runner.name[0])
    ? t(`asianView.labels.selection.${runner.name[0]}`)
    : '';
};

export const getHandicapLine = ({
  runners,
  handicap,
  isAsianHandicap,
  firstKeyLineId,
  secondKeyLineId
}: {
  runners: TAsianViewRunner[];
  handicap: number;
  isAsianHandicap: boolean;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
}) => {
  const homeLineHandicap = handicap;
  const awayLineHandicap = isAsianHandicap ? homeLineHandicap * -1 : homeLineHandicap;
  return runners.filter(runner => {
    return (
      (+runner.id === firstKeyLineId && homeLineHandicap === +(runner.handicap || 0)) ||
      (+runner.id === secondKeyLineId && awayLineHandicap === +(runner.handicap || 0))
    );
  });
};

export const getAVRunnersByIndex = ({
  index,
  market,
  runnerStatuses,
  isClosed,
  isGoalLines,
  isAsianHandicap,
  isKeyLine,
  firstKeyLineHandicap,
  marketPricesId,
  firstKeyLineId,
  secondKeyLineId
}: {
  index: number;
  market: TAsianViewMarket;
  runnerStatuses: TMarketPricesRunner[];
  isClosed: boolean;
  isAsianHandicap: boolean;
  isGoalLines: boolean;
  isKeyLine: boolean;
  firstKeyLineHandicap?: number;
  marketPricesId?: string;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
}) => {
  const allRunners = !isClosed ? [...market.runners] : [];
  const runners = allRunners.filter(runner => {
    const curRunnerStatus = runnerStatuses.find(runnerStatus => {
      return +runner.id === runnerStatus.id && +(runner.handicap || 0) === runnerStatus.handicap;
    });
    return !curRunnerStatus || curRunnerStatus?.status === RunnersStatuses.ACTIVE;
  });

  if (isAsianHandicap || isGoalLines) {
    if (isKeyLine) {
      const homeHandicap = firstKeyLineHandicap || 0;
      const hasNextLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;
      const hasPrevLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap - 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;

      const lineIndex =
        (index === 1 && !hasNextLine) || (index === 2 && hasPrevLine && hasNextLine)
          ? -1
          : index === 2 && !hasNextLine
          ? -2
          : index;

      const runnersGroup = getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25 * lineIndex,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      });

      if (isGoalLines) {
        runnersGroup.reverse();
      }

      return runnersGroup;
    } else if (marketPricesId) {
      return [];
    }
  }

  if (market.marketType.includes(MARKET_TYPES.firstHalfGoals)) {
    runners.reverse();
  }

  return runners;
};
