import classNames from 'classnames';

import { ModalClasses } from 'types';

import MarketRules from '../MarketRules';

import styles from './styles.module.scss';

interface ColumnNameProps {
  name: string;
  marketId?: string | null;
  modalClasses?: ModalClasses;
}

const ColumnName = ({ name, marketId, modalClasses }: ColumnNameProps) => {
  const hasRules = !!marketId;

  return (
    <div className={classNames(styles.name, { [styles.name__hasRules]: hasRules })}>
      <div className={classNames(styles.name__value, { [styles.name__value__withRules]: hasRules })}>{name}</div>
      {hasRules && <MarketRules marketId={marketId} modalClasses={modalClasses} />}
    </div>
  );
};

export default ColumnName;
