import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CURRENCY_ALL_VALUE } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import MobileSelect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileSelect';
import { getCurrenciesDropdownItems } from 'redux/modules/appConfigs/selectors';
import { setMobileFilters } from 'redux/modules/myBets';
import { getMyBetsMultiCurrencyValue } from 'redux/modules/myBets/selectors';
import { DropdownItem } from 'types';
import { TMobileSelectOption } from 'types/myBets';

const MobileFiltersCurrencies = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMyBetsPage } = useAccountUrlParams();

  const currenciesDropdownItems = useSelector(getCurrenciesDropdownItems);
  const dropdownItems = useMemo(() => {
    const items: DropdownItem[] = [...currenciesDropdownItems];

    if (isMyBetsPage) {
      items.unshift({
        id: CURRENCY_ALL_VALUE,
        value: CURRENCY_ALL_VALUE,
        label: t('account.currencies.all'),
        isLabelTranslatable: true
      });
    }

    return items;
  }, [currenciesDropdownItems, isMyBetsPage, t]);

  const currencyValue = multiCurrencyValue || dropdownItems[0].id;

  const currenciesOptions: TMobileSelectOption[] = dropdownItems.map(option => {
    const { id, label, value } = option;

    return {
      id: id.toString(),
      label: label ?? '',
      isSelected: value === currencyValue,
      defaultIsSelected: value === currencyValue
    };
  });

  const changeCurrenciesHandler = (currenciesChanged: TMobileSelectOption[], isCurrencyChanged: boolean) => {
    dispatch(
      setMobileFilters({
        currency: currenciesChanged.find(({ isSelected }) => isSelected)?.id ?? '',
        isCurrencyChanged
      })
    );
  };

  return (
    <MobileSelect
      title={t('account.labels.currencies')}
      defaultOptions={currenciesOptions}
      onChangeCallback={changeCurrenciesHandler}
    />
  );
};

export default MobileFiltersCurrencies;
