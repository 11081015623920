import { Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { accountPagesBranding } from 'constants/branding';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import BetCancelledNotification from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetCancelledNotification/BetCancelledNotification';
import BetDetails from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetDetails/BetDetails';
import BetInfo from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetInfo/BetInfo';
import BetSelection from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetSelection/BetSelection';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { getCancelledBets } from 'redux/modules/betList/selectors';
import { TCurrentBetWithBetsGroupedById } from 'redux/modules/betList/type';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { shouldSwapColors } from 'utils/myBetsValues';

import styles from './OpenBet.module.scss';

interface OpenBetProps {
  bet: TCurrentBetWithBetsGroupedById;
  isEmbedded?: boolean;
}

const OpenBet = ({ bet, isEmbedded }: OpenBetProps) => {
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { isBackBet, isLayBet } = useOpenBetData(bet);
  const cancelledBets = useSelector(getCancelledBets);
  const isCancelled = cancelledBets.includes(bet.offerId);
  const swapColorsEnabled = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket);
  const isBackIndicator = swapColorsEnabled ? isLayBet : isBackBet;
  const isPartiallyMatchedBetRef = useRef<boolean>(!!bet.betsByOfferId.length);
  const shouldShowCancelledNotification = isCancelled && !isPartiallyMatchedBetRef.current;

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: bet.marketId,
    eventId: bet.eventId,
    section: MarketsPricesSocketParamsSections.SportsBetList,
    observerOptions: { rootMargin: '200px' }
  });

  return (
    <div
      ref={ref}
      className={classNames(styles.bet, accountPagesBranding.BET_INDICATOR, {
        [styles.bet__embedded]: isEmbedded,
        [styles.bet__lay]: !isBackIndicator,
        [styles.bet__cancelled]: isCancelled,
        [styles.bet__noBorder]: isCancelled && !bet.betsByOfferId.length,
        [accountPagesBranding.BET_SIDE_BACK]: isBackIndicator,
        [accountPagesBranding.BET_SIDE_LAY]: !isBackIndicator
      })}
    >
      {shouldShowCancelledNotification && <BetCancelledNotification bet={bet} />}
      {!shouldShowCancelledNotification && (
        <>
          <BetSelection
            bet={bet}
            hideExtendedInfo={isEmbedded}
            hidePlTable={isEmbedded}
            hideInPlayIcon={isEmbedded}
            withOfferState={isEmbedded}
          />
          {!isEmbedded && <span className={styles.separator} />}
          <BetDetails bet={bet} isCancelled={isCancelled} hideOfferState={isEmbedded} />
          {bet.betsByOfferId.map(betData => (
            <Fragment key={`${betData.offerId}_${betData.offerState}`}>
              <span className={styles.separator} />
              {isCancelled && (
                <BetCancelledNotification bet={betData} isPartiallyMatched={isPartiallyMatchedBetRef.current} />
              )}
              {!isCancelled && <BetDetails bet={betData} isCancelled={cancelledBets.includes(betData.offerId)} />}
            </Fragment>
          ))}
          <BetInfo bet={bet} />
        </>
      )}
    </div>
  );
};

export default OpenBet;
