import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import CashOutButton from 'components/CashOutButton';
import FavoriteStar from 'components/FavoriteStar';
import ViewFullMarket from 'components/FeaturedMarkets/components/ViewFullMarket';
import MarketCollapseRow from 'components/MarketCollapseRow';
import MarketRunner from 'components/MarketRunner';
import MarketSelections from 'components/MarketSelections';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import WhatIf from 'components/WhatIf';
import { Devices, FAVORITES_TYPES, PageBlocks, tooltipStatus } from 'constants/app';
import { CELLS_ON_MARKET_PAGE } from 'constants/tooltip';
import useCellConfigs from 'hooks/useCellConfigs';
import useLayColumn from 'hooks/useLayColumn';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getSelectedBetsAmountByMarket } from 'redux/modules/betslip/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { resetMarketsPricesByMarket } from 'redux/modules/marketsPrices';
import {
  getMarketPricesBettingType,
  getMarketPricesCurrency,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesId,
  getMarketPricesIsBettingEnabled,
  getMarketPricesMarketType,
  getMarketPricesSecondKeyLineHandicap,
  getMarketPricesSecondKeyLineId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { PageBlock, PlacementPage } from 'types';
import { EventMarketClasses, IMarket, IMarketRules } from 'types/markets';
import { isLineBettingType } from 'utils/betslip';
import { getBetGroups, getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

interface EventMarketProps {
  /**
   * Passed Event market
   */
  market: IMarket;
  /**
   * Collapsed markets ids and statuses
   */
  collapsedMarkets: Record<string, boolean>;
  /**
   * Market collapse trigger
   */
  setMarketCollapse: (marketId: string, value: boolean) => void;
  /**
   * Current market index in list
   */
  marketIndex: number;
  /**
   * Method for requesting rules for a particular market
   * @param marketId
   */
  onFetchMarketRules?: (marketId: string, sportId: string) => void;
  /**
   * Determines if rules are loaded or not
   */
  rulesLoading?: boolean;
  /**
   * Markets rules collection
   */
  marketsRules: Record<string, IMarketRules> | null;
  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock?: PageBlock;
  /**
   * Classes to customize component styles
   */
  classes?: EventMarketClasses;
  /**
   * BIAB className for collapse title
   */
  collapseTitleClassName?: string;
  /**
   * Should arrow icon be on the right side
   */
  isRightArrow?: boolean;
  /**
   * Show deep prices for runner or nor
   */
  showDeepPrices?: boolean;
  /**
   * BIAB className for collapse in play icon
   */
  collapseInPlayClassName?: string;
  /**
   * Shouldn't be collapsed by default never
   */
  noCollapsedByDefault?: boolean;
  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * Should in play status (in collapse row) be at the end or at the beginning
   */
  collapseInPlayStatusFirst?: boolean;
  /**
   * Show tooltip for market table or not
   */
  showMarketTableTooltip?: boolean;
  /**
   * URL search params for redirect to single market page
   */
  redirectToSingleMarketSearchParams?: string;
  /**
   * Callback fired when redirected to a single market page
   * @param market
   */
  onRedirectToSingleMarketPage?: (market: IMarket) => void;
  /**
   * Double cell container width for desktop
   */
  doubleCellsWidth?: boolean;
  /**
   * Show empty columns if market depth is disabled
   */
  showEmptyColumns?: boolean;
  isOtherSection?: boolean;
  page: PlacementPage;
  disableRemoveSocketParams?: boolean;
}

const EventMarket = ({
  market,
  collapsedMarkets,
  setMarketCollapse,
  marketIndex,
  onFetchMarketRules = () => {},
  rulesLoading,
  marketsRules,
  pageBlock,
  classes,
  collapseTitleClassName,
  showDeepPrices = false,
  collapseInPlayClassName,
  noCollapsedByDefault = false,
  redirectToSingleMarketPage,
  collapseInPlayStatusFirst = false,
  showMarketTableTooltip = true,
  redirectToSingleMarketSearchParams,
  onRedirectToSingleMarketPage,
  doubleCellsWidth = false,
  showEmptyColumns = false,
  isOtherSection = false,
  page,
  disableRemoveSocketParams = false
}: EventMarketProps) => {
  const { inPlay, marketName, marketId, eventType, marketStartTime, cashOutEnabled } = market;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const marketPricesId = useSelector(getMarketPricesId(marketId));
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(marketId));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(marketId));
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(marketId));
  const firstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(marketId));
  const firstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(marketId));
  const secondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(marketId));
  const secondKeyLineHandicap = useSelector(getMarketPricesSecondKeyLineHandicap(marketId));
  const bettingType = useSelector(getMarketPricesBettingType(marketId));
  const marketType = useSelector(getMarketPricesMarketType(marketId));
  const isFavorite = useSelector(getFavoriteById(marketId));
  const selectedBetsAmountByMarket = useSelector(getSelectedBetsAmountByMarket(marketId));

  const collapsed =
    (!noCollapsedByDefault && marketIndex > 2 && collapsedMarkets[marketId] === undefined) ||
    (marketId in collapsedMarkets && collapsedMarkets[marketId]);

  const { translationKey, isShowIcon, isEnabled } = useTooltip(CELLS_ON_MARKET_PAGE);
  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount: showDeepPrices ? 3 : 1,
    doubleWidth: doubleCellsWidth,
    showDepth: showEmptyColumns,
    addMobileExtraSpace: true
  });
  const { showStatus, status, showLockIcon, displayStatus } = useMarketStatusAndLockIcon(
    bettingEnabled,
    marketPricesStatus
  );
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId: market.event.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' },
    disableRemove: disableRemoveSocketParams
  });
  const { isLayColumnEnabled } = useLayColumn({
    sportId: market.eventType.id,
    marketId: market.marketId,
    bettingType: market.description.bettingType
  });

  let runners = market.runners;
  const isDesktop = device === Devices.DESKTOP;
  const displayEmptyColumns = showEmptyColumns && isDesktop && !showDeepPrices;
  const isShowCashOut = pageBlock
    ? ([PageBlocks.TOP_5_VIEW, PageBlocks.MULTI_MARKET_VIEW] as PageBlock[]).includes(pageBlock)
    : false;
  const isLineMarket = isLineBettingType(market.description.bettingType);
  const betGroups = getBetGroups(showDeepPrices, isLayColumnEnabled, isLineMarket);

  const whatIfClasses = useMemo(() => ({ container: styles.eventMarket__whatIf }), []);

  const favoriteData = {
    starred: !isFavorite,
    entryId: marketId,
    entryName: marketName,
    entryType: FAVORITES_TYPES.market,
    sportId: eventType.id
  };

  if (marketPricesId && marketType && bettingType) {
    const marketTypes = getMarketTypes(marketType, bettingType);

    if (marketTypes?.isHandicap && firstKeyLineId && secondKeyLineId) {
      runners = runners.filter(runner => {
        return (
          (runner.selectionId === firstKeyLineId && (firstKeyLineHandicap || 0) === +(runner.handicap || 0)) ||
          (runner.selectionId === secondKeyLineId && (secondKeyLineHandicap || 0) === +(runner.handicap || 0))
        );
      });
    }
  }

  const collapseMarket = () => {
    setMarketCollapse(marketId, !collapsed);
  };

  const arrow = (
    <i
      className={classNames('biab_collapse-toggler fa2', styles.eventMarket__collapseArrow, {
        'fa2-arrow-up': !collapsed,
        'fa2-arrow-down': collapsed
      })}
    />
  );

  useEffect(() => {
    return () => {
      if (isDesktop && !selectedBetsAmountByMarket && !disableRemoveSocketParams) {
        dispatch(resetMarketsPricesByMarket(marketId));
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={classNames('biab_event-market', styles.eventMarket, classes?.market ?? '')}
      data-collapsed={collapsed ?? false}
      data-market-id={marketId}
      data-event-id={market.event.id}
      data-market-prices={true}
    >
      <MarketCollapseRow
        onMarketCollapse={collapseMarket}
        inPlay={inPlay}
        marketId={marketId}
        onFetchMarketRules={onFetchMarketRules}
        rulesLoading={rulesLoading}
        marketStartTime={marketStartTime}
        marketName={marketName}
        sportId={eventType.id}
        className={collapseTitleClassName}
        classes={{ header: classes?.collapseHeader }}
        rightIcon={arrow}
        marketsRules={marketsRules}
        inPlayClassName={collapseInPlayClassName}
        isMarketCashOutEnabled={cashOutEnabled}
        inPlayStatusFirst={collapseInPlayStatusFirst}
      >
        <FavoriteStar entryData={favoriteData} className={styles.eventMarket__starIcon} />
        {marketName}
      </MarketCollapseRow>

      {!collapsed && (
        <>
          {cashOutEnabled && isShowCashOut && !tooltipStatus[status || ''] && (
            <CashOutButton marketId={marketId} partialCashOutEnabled={market.partialCashOutEnabled} />
          )}
          {marketPricesId && (
            <MarketSelections
              market={market}
              showDeepPrices={showDeepPrices}
              classes={{ header: classes?.marketTableHeader }}
              doubleCellsWidth={doubleCellsWidth}
              showEmptyColumns={displayEmptyColumns}
            />
          )}
          <div className={styles.eventMarket__runnersContainer}>
            {marketPricesId &&
              runners.map((runner, runnerIndex) => {
                return (
                  <MarketRunner
                    key={`${runner.selectionId}-${runnerIndex}`}
                    market={market}
                    runner={runner}
                    marketStatusSettings={{
                      showStatus,
                      status,
                      showLockIcon,
                      displayStatus
                    }}
                    isLineMarket={isLineMarket}
                    isLayColumnEnabled={isLayColumnEnabled}
                    betGroups={betGroups}
                    desktopCellsContainerWidth={desktopCellsContainerWidth}
                    mobileCellsContainerWidth={mobileCellsContainerWidth}
                    marketPricesCurrency={marketPricesCurrency}
                    pageBlock={pageBlock}
                    redirectToSingleMarketPage={redirectToSingleMarketPage}
                    showTooltip={showMarketTableTooltip}
                    redirectToSingleMarketSearchParams={redirectToSingleMarketSearchParams}
                    onRedirectToSingleMarketPage={onRedirectToSingleMarketPage}
                    showEmptyColumns={displayEmptyColumns}
                    isOtherSection={isOtherSection}
                    page={page}
                    containerClassName={classes?.runnersContainer}
                    tooltip={
                      <>
                        {marketIndex === 0 && runnerIndex === 0 && !market?.closedDate && (
                          <>
                            {isShowIcon && isEnabled && (
                              <i
                                data-tooltip-id={'tooltip'}
                                data-tooltip-html={unescape(t(translationKey))}
                                className={'fa2 fa2-info-circle tooltip-icon cursor-help'}
                              >
                                <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
                                <span className="path2" />
                              </i>
                            )}
                          </>
                        )}
                      </>
                    }
                  >
                    <div className={classNames('biab_game-title', styles.eventMarket__runnerName)}>
                      <span>{runner.runnerName}</span>
                      {marketPricesId && (
                        <WhatIf
                          marketId={marketId}
                          commission={market.commission}
                          eachWayDivisor={market.description?.eachWayDivisor}
                          marketType={market.description?.marketType}
                          selectionId={runner.selectionId}
                          handicap={runner.handicap}
                          classes={whatIfClasses}
                          pageBlock={pageBlock}
                        />
                      )}
                    </div>
                  </MarketRunner>
                );
              })}
            {showStatus && <StatusOverlay status={status} inMiddle />}
          </div>
          {isDesktop && (
            <ViewFullMarket marketId={marketId} sportId={eventType.id} classes={{ container: classes?.viewMarket }} />
          )}
        </>
      )}
    </div>
  );
};

export default memo(EventMarket);
